import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container, ContainerWide } from '../components/Grid/Grid';
import SEO from '../components/SEO';
import Cover from '../components/Cover/Cover';
import GridCards, { Card, CardOverlayLargeText } from '../components/GridCards/GridCards';
import Button from '../components/Button/Button';
import IconCircle from '../components/IconCircle/IconCircle';
import DiscoverMore from '../components/DiscoverMore/DiscoverMore';
import SR from '../components/SR';
import BR from '../components/BR';

import icon1 from '../images/icons/icon-6.svg';
import icon2 from '../images/icons/icon-5.svg';
import cardImageLogo from '../images/card-logo-dark.svg';

const IndexPage = ({ data }) => (
    <Layout>

        <SEO title="Home" description="Developing great business working with the planet not against it." />

        <SR><h1>One Planet Retail</h1></SR>

        <Band size="xs" pb={0}>
            <ContainerWide>
                <GridCards>
                    <Card image={cardImageLogo} theme="white" />
                    <Card image={data.cardImage1}>
                        <CardOverlayLargeText>We introduce ethical brands to buyers</CardOverlayLargeText>
                    </Card>
                    <Card image={data.cardImage2}>
                        <CardOverlayLargeText>Together we can achieve great things</CardOverlayLargeText>
                    </Card>
                </GridCards>
            </ContainerWide>
        </Band>

        <Band size="sm">
            <Container>
                <DiscoverMore>
                    <p>Developing great business working with the planet not against it.</p>
                    <Button href="#more">Discover More</Button>
                </DiscoverMore>
            </Container>
        </Band>

        <Band theme="gray" size="md" id="more">
            <Container>
                <h2 style={{ marginBottom: 15 }}>What we do</h2>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We introduce brands to buyers and a network of new commercial opportunities, <BR />
                    for the benefit of both brands and buyers.</p>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We research and promote brands to alternative areas of retail business who are looking to meet the <BR />
                    ever-growing consumer demands for environmentally friendly, sustainable and cruelty free products.</p>
                <p style={{ marginBottom: 0, lineHeight: 1.65 }}>We work to achieve your goals in a professional and engaging way by bringing ethical brands and buyers together.</p>
            </Container>
        </Band>

        <Cover image={data.coverImage1} />

        <Band size="lg">
            <Container>
                <h2>Working to develop great business with the planet not against it.</h2>
                <IconCircle icon={icon1} />
                <h3>Our Approach</h3>
                <p>We are a team of commercially minded engaging and creative professionals utilising our network of <BR />
                    contacts to introduce brands and buyers in the UK and internationally.</p>
                <p>We work with commercially credible brands who are environmentally friendly, cruelty free and <BR />
                    use sustainable materials.</p>
                <p>We work with buyers across the retail spectrum who recognise the growing consumer concerns and demands <BR />
                    for ethical, sustainable, and cruelty free products.</p>
                <p>Our membership of APPGVV (all-party parliamentary group on vegan and vegetarianism) and APDAWG (all-party <BR />
                    parliamentary dog advisory welfare group) gives us an insight into some of the challenges we are facing in our <BR />
                    world today and has introduced us to a network of professional contacts and expertise associated with our <BR />
                    environment, human and animal welfare.</p>
                <IconCircle icon={icon2} />
                <h3>Our Values</h3>
                <p>We are honest, transparent and mindful of the urgent need to reduce the impact on our own future health, the <BR />
                    environment and wildlife conservation.</p>
                <p>We work with brands who believe that their own positive commercial decisions and policy making must be <BR />
                    aligned in a caring and impactful way to make a real difference to the conservation of the planet 'our home'. <BR />
                    We work with buyers who share these values.</p>
                <p>We support anti slavery, cruelty free, sustainable and environmentally friendly values.</p>
                <p>We are looking to partner with charities whose work is based on research and data to enhance the future of <BR />
                    human, animal and environmental welfare.</p>
            </Container>
        </Band>

    </Layout>
);

export const pageQuery = graphql`
    query {
        cardImage1: file(relativePath: { eq: "card-11.jpg" }) {
            ...cardImageFragment
        }
        cardImage2: file(relativePath: { eq: "card-10.jpg" }) {
            ...cardImageFragment
        }
        coverImage1: file(relativePath: { eq: "cover-4.jpg" }) {
            ...coverImageFragment
        }
    }
`

export default IndexPage;
